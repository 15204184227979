import { useAuthStore } from '~/store/auth.js'

export default defineNuxtRouteMiddleware((to) => {
  if (process.server) return

  const authStore = useAuthStore()

  if (authStore.isAuthenticated) {
    return navigateTo('/quote-cases/create')
  }
})
